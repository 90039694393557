import {Route} from "react-router-dom"
import React, { Component } from 'react';
import Axios from 'axios'
class PublicRoute extends Component {
    state = {  }
    render() {
        window.gtag_report_conversion_page_view()
        Axios.post('https://hosting.breezelabs.co.uk/api/stats/page-visit', {referer: document.referrer ? document.referrer : 'searchbar', site: window.location.href})
        return (
            <Route exact={this.props.exact} path={this.props.path}> {this.props.children} </Route>  
        )
    }
}

export default (PublicRoute);
